import {Component, OnDestroy, OnInit} from '@angular/core';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../mock-data.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'consumption-alert-widget',
    templateUrl: './widget.consumption-alert.html',
    styleUrls: ['./widget.consumption-alert.scss'],
    providers: [Globals]
})

export class ConsumptionAlertWidget implements OnInit, OnDestroy {

    currentZone: 'lower' | 'higher' = 'lower';
    showPowerInfo = false;

    powerText = '';
    private powerValue: number = null;

    private mockSubscriptions: Subscription[] = [];
    private liveValueSubscription: Subscription = null;

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private mockData: MockDataService) {
    }

    /**
     * Widget Verbrauchsalarm ist aktuell deaktiviert und ohne Funktion
     */
    ngOnInit() {
        if (this._apiService.isDemoMode()) {
            const s = this.mockData.onCurrentConsumptionValue.subscribe(
                (res: { power: number, timestamp: number }) => this.updateComparison(res.power)
            );
            this.mockSubscriptions.push(s);
            this.getHomeStateMock();
            return;
        }

        this.liveValueSubscription = this._apiService.onCurrentConsumption.subscribe(
            (res) => this.updateComparison(res.data.electricity.power)
        );
        this.getHomeState();
    }

    ngOnDestroy(): void {
        for (const element of this.mockSubscriptions) {
            if (element) {
                element.unsubscribe();
            }
        }

        if (this.liveValueSubscription) {
            this.liveValueSubscription.unsubscribe();
        }
    }

    private getHomeState(): void {
        const s = this._apiService.getHomeStateConfig().subscribe(
            (res) => {
                this.handleHomeStateConfig(res.data);
            });

        this.mockSubscriptions.push(s);
    }

    private getHomeStateMock(): void {
        const s = this.mockData.getHomeStateConfig().subscribe((res) => {
            if (res.status === 'ok') {
                this.handleHomeStateConfig(res.data);
            }
        });

        this.mockSubscriptions.push(s);
    }

    private handleHomeStateConfig(config: any): void {
        try {
            if (config.thresholds.length < 1) {
                this.powerText = `${0} Watt`;
                this.powerValue = 0;
            } else {
                this.powerText = `${Math.round(config.thresholds.reduce((a, b) => Math.max(a, b))).toLocaleString('de')} Watt`;
                this.powerValue = Math.round(config.thresholds.reduce((a, b) => Math.max(a, b)));
            }
            this.showPowerInfo = true;
        } catch (e) {
            console.log('Error', e);
        }
    }

    private updateComparison(current_value): void {
        if (this.powerText) {
            this.currentZone = current_value < this.powerValue ? 'lower' : 'higher';
        }
    }

    firstDetailViewAnalytics() {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Consumption-Details'
                }
            });
        }
        this._globals.setFirstDetailsViews();
    }
}
