import {Component, OnInit, OnDestroy, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';
import {Angulartics2} from 'angulartics2';

import {ApiService} from '../services/api.service';

import {OverlayService} from '../overlay/overlay.service';
import {UserService} from '../user.service';
import {getProviderIconClass, getProviderMenuName} from '../ProivderUtil';
import {objectIsEmpty} from '../Util';
import {forkJoin} from 'rxjs';
import {NilmOverlayResult} from '../overlay/overlay.component';
import {MockDataService} from '../mock-data.service';
import {constants, static_links} from '../constants';

@Component({
    selector: 'iona-app',
    templateUrl: './dashboard.component.html',
})

export class DashboardComponent implements OnInit, OnDestroy {

    private refresh = null;
    private nilm_refresh = null;

    // Sortierung der Dashboard-Widgets
    sort_options: any = {
        onUpdate: (event: any) => {
            this._userService.setActiveUserCharts(this.charts_selected);
            let card: string = this.charts.filter((item: any) => item.id === this.charts_selected[event.newIndex].id)[0].name;
            this._analytics.eventTrack.next({
                action: 'dashboard_layout_change',
                properties: {
                    category: 'Dashboard',
                    label: 'type: move; card: ' + card + '; position: ' + (event.newIndex + 1) + '; previous_position: ' + (event.oldIndex + 1)
                }
            });
        }
    };

    providerclass: string = ''; // innogy/enviaM/...
    providername: string = '';
    providerMenuName: string = null;
    privacyLink = '';
    username: string = null;

    userHasPlug: boolean = false;
    userPlugAttributes: any = null;

    /**
     * Alle auswählbareb Charts
     */
    charts: any = [
        {
            id: 'live',
            name: 'Live',
            info: 'Anzeige Ihres Stromverbrauchs in Echtzeit'
        },
        {
            id: 'today',
            name: 'Heute',
            info: 'Ihr heutiger Stromverbrauch und die entstandenen Kosten im Überblick'
        },
        {
            id: 'consumer',
            name: 'Meine Geräte',
            info: 'Anzeige des Verbrauchs einzelner Geräte in Ihrem Haushalt und der dabei entstandenen Kosten'
        },
        {
            id: 'compare',
            name: 'Vergleich',
            info: 'Verbrauchsstatistik und Analysen'
        },
        {
            id: 'meter',
            name: 'Zählerstand',
            info: 'Anzeige Ihres Zählerstands'
        },
        {
            id: 'finance',
            name: 'Abschlags-Check',
            info: 'Gutschrift- oder Nachzahlungsprognose'
        },
        {
            id: 'consumption-alert',
            name: 'Verbrauchs-Alarm',
            info: 'Alarmfunktion bei hohem Stromverbrauch'
        }
        // {id: 'powerchecker', name: 'PowerChecker', info: 'Innogy PowerChecker'}
    ];

    /**
     * Verfügbare Charts
     */
    charts_available: any = [
        // {id: 'live'}, {id: 'today'}, {id: 'consumer'}, {id: 'compare'}, {id: 'meter'}, {id: 'finance'}
    ];

    /**
     * Ausgewählte Charts / Default ausgewählte Charts
     */
    charts_selected: any = [
        // { id: 'live' }, { id: 'today' }, { id: 'consumer' }, { id: 'compare' }, { id: 'meter' }, { id: 'finance' }, { id: 'consumption-alert' }
    ];

    version: string = '0.0.0';
    changelog: any = [];
    logs: boolean = false;

    private last_popup_shown: Date = null;

    private onVisibilityFn: () => void;

    constructor(private _titleService: Title,
                private _notification: ToastrService,
                private _analytics: Angulartics2,
                public _apiService: ApiService,
                private overlay_service: OverlayService,
                private _userService: UserService,
                private mockData: MockDataService,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        history.pushState({'isDashboard': true}, 'Dashboard', '/#/');

        this._titleService.setTitle('Übersicht | iONA');

        this.username = this._userService.getActiveUserName();
        this.providername = this._userService.getActiveUserProvider();
        this.providerclass = getProviderIconClass(this.providername);
        this.privacyLink = static_links.privacy[this.providername];
        this.providerMenuName = getProviderMenuName(this.providername);

        /**
         * initialize the overlay to use the correct configuration
         */
        this.setupOverlay();
        this.initCharts();
        this.getVersionChangelog();

        this.onVisibilityFn = this.renderer.listen('document', 'visibilitychange',
            () => {
                if (!document.hidden) {
                    if (this._apiService.isDemoMode()) {
                        this.checkMockNilmStatus();
                        return;
                    }
                    this.checkNilmStatus();
                }
            }
        );

        if (this._apiService.isDemoMode()) {
            this.checkMockNilmStatus();
            this.getMockRegistrationModel();
            return;
        }

        this._apiService.checkToken();
        this._apiService.checkMeterStatus();
        this.checkNilmStatus();
        this.getRegistrationModel();


        /**
         * Wiederkehrende Prüfung, ob der Nutzer eingeloggt ist
         */
        this.refresh = setInterval(() => {
            this._apiService.checkToken();
        }, 10000);

        this.nilm_refresh = setInterval(() => {
            this.checkNilmStatus();
        }, 300000);

    }

    ngOnDestroy() {
        if (this.refresh) {
            clearInterval(this.refresh);
        }

        if (this.nilm_refresh) {
            clearInterval(this.nilm_refresh);
        }

        if (this.onVisibilityFn) {
            this.onVisibilityFn();
        }

    }


    /**
     * Check the NILM status and display an overlay if something changed
     */
    private checkNilmStatus(): void {

        const profile_sub = this._apiService.getProfile();
        const nilm_sub = this._apiService.getNilmStatus();
        forkJoin([profile_sub, nilm_sub]).subscribe(
            (responses) => {
                const profile_data = responses[0];
                const nilm_data = responses[1];
                if (profile_data['status'] === 'ok' && 'data' in profile_data) {
                    if (nilm_data['status'] === 'ok' && 'data' in nilm_data) {
                        this.determineNewlyAddedAppliances(nilm_data, profile_data);
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    /**
     * Mock test
     */
    private checkMockNilmStatus(): void {
        const profile_sub = this.mockData.getProfileAttributes();
        const nilm_sub = this.mockData.getNILMStatus();

        forkJoin([profile_sub, nilm_sub]).subscribe(
            (responses) => {
                const profile_data = responses[0];
                const nilm_data = responses[1];
                if (profile_data['status'] === 'ok' && 'data' in profile_data) {
                    if (nilm_data['status'] === 'ok' && 'data' in nilm_data) {
                        this.determineNewlyAddedAppliances(nilm_data, profile_data);
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    private getMockRegistrationModel(): void {
        this.mockData.getRegistrationModel().subscribe(
            (response) => {
                if ('data' in response) {
                    if ('model_identifier' in response.data) {
                        this.handlePlugResponse(response);
                    }
                }
            }
        );
    }

    private getRegistrationModel(): void {
        this._apiService.getRegistrationModel().subscribe(
            (response) => {
                if ('data' in response) {
                    if ('model_identifier' in response.data) {
                        this.handlePlugResponse(response);
                    }
                }
            }
        );
    }

    /**
     * Get current version
     */
    private getVersionChangelog() {
        this._apiService.getChangelog().subscribe(
            (data: any) => {
                if (0 in data) {
                    this.version = data[0].version;
                }
                this.changelog = data;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    private handlePlugResponse(response: any): void {
        const model = response.data.model_identifier;

        switch (model) {
            case constants.application.devices.plug:
                this._userService.updateUserDevice(constants.application.devices.plug);
                this.userHasPlug = true;
                break;
            case constants.application.devices.box:
                this._userService.updateUserDevice(constants.application.devices.box);
                break;
        }

        if (this.userHasPlug) {
            const p = {id: 'powerchecker', name: 'PowerChecker', info: 'Schalten Ihres PowerCheckers'};
            this.charts.splice(1, 0, p);

            this.charts_available = [];
            this.charts_selected = [];
            this.initCharts();
        }
    }

    /**
     * Initialize available charts
     */
    private initCharts(): void {
        for (let chart of this.charts) {
            this.charts_available.push({
                id: chart.id
            });
        }

        if (this._apiService.isDemoMode()) {
            this.charts_selected = [{id: 'live'}, {id: 'powerchecker'}, {id: 'today'}, {id: 'consumer'}, {id: 'compare'}, {id: 'meter'}, {id: 'finance'}, {id: 'consumption-alert'}];
            for (let chart of this.charts_selected) {
                this.charts_available = this.charts_available.filter((item: any) => item.id !== chart.id);
            }
        } else {
            const charts = this._userService.getActiveUserCharts();
            if (charts === null || charts === undefined) {
                this.charts_selected = [{id: 'live'}, {id: 'today'}, {id: 'consumer'}, {id: 'compare'}, {id: 'meter'}, {id: 'finance'}, {id: 'consumption-alert'}];
                if (this.userHasPlug) {
                    this.charts_selected = [{id: 'live'}, {id: 'powerchecker'}, {id: 'today'}, {id: 'consumer'}, {id: 'compare'}, {id: 'meter'}, {id: 'finance'}];
                }

                for (let chart of this.charts_selected) {
                    this.charts_available = this.charts_available.filter((item: any) => item.id !== chart.id);
                }

                this._userService.setConsumptionAlertAttribute(false);
                this._userService.setActiveUserCharts(this.charts_selected);

            } else if (objectIsEmpty(charts)) {

                const consumption_alert_attr = this._userService.getConsumptionAlertAttribute();
                let changes_made = false;
                if (consumption_alert_attr === null || consumption_alert_attr === undefined) {
                    console.log('no attribute');
                    this.charts_selected.push({id: 'consumption-alert'});
                    this._userService.setConsumptionAlertAttribute(false);
                    changes_made = true;
                } else if (consumption_alert_attr) {
                    console.log('is attribute');
                    this.charts_selected.push({id: 'consumption-alert'});
                    this._userService.setConsumptionAlertAttribute(false);
                    changes_made = true;
                }

                for (let chart of this.charts_selected) {
                    this.charts_available = this.charts_available.filter((item: any) => item.id !== chart.id);
                }
                if (changes_made) {
                    this._userService.setActiveUserCharts(this.charts_selected);
                }

            } else {
                if (charts.length > 0) {
                    this.charts_selected = charts;


                    // todo here check things man consumption alter pilot
                    let changes_made = false;
                    const consumption_alert_attr = this._userService.getConsumptionAlertAttribute();
                    if (consumption_alert_attr === null || consumption_alert_attr === undefined) {
                        console.log('no attribute');
                        this.charts_selected.push({id: 'consumption-alert'});
                        this._userService.setConsumptionAlertAttribute(false);
                        changes_made = true;
                    } else if (consumption_alert_attr) {
                        console.log('is attribute');
                        const found = this.charts_selected.find((el) => el.id === 'consumption-alert');
                        if (!found) {
                            this.charts_selected.push({id: 'consumption-alert'});
                        }
                        this._userService.setConsumptionAlertAttribute(false);
                        changes_made = true;
                    }

                    for (let chart of this.charts_selected) {
                        this.charts_available = this.charts_available.filter((item: any) => item.id !== chart.id);
                    }

                    if (changes_made) {
                        this._userService.setActiveUserCharts(this.charts_selected);
                    }
                }
            }
        }

    }

    /**
     * Setup the appearing overlay
     */
    private setupOverlay(): void {
        this.overlay_service.onConfirm.subscribe(
            (result: NilmOverlayResult) => {
                this._userService.updateActiveUserNilmStatusForAppliance(result.tag, result.amount);
                const change = {Appliance: {}};
                change.Appliance[result.id] = result.amount;
                if (!this._apiService.isDemoMode()) {
                    this._apiService.setProfile(change);
                }
            }
        );
    }

    /**
     * Fit
     * @param data
     * @param device_map
     */
    private filterNilmDeviceModelCount(data: any, device_map) {
        const result = {
            timeBasedAppliances: {
                dishWasher: null,
                dryer: null,
                oven: null,
                washingMachine: null
            }
        };
        for (const category of device_map) {
            const response_category = data[category.key];
            if (response_category === null || response_category === undefined) {
                continue;
            }
            for (const dev of category.elements) {
                const value = response_category[dev].models;
                if (value === null || value === undefined) {
                    continue;
                }
                result[category.key][dev] = value;
            }
        }
        return result;
    }

    /**
     *
     */
    private determineNewlyAddedAppliances(response, profile_data): void {
        const old_nilm = this._userService.getActiveUserNilmStatus();
        if (old_nilm === null || old_nilm === undefined) {
            this._userService.updateActiveUserNilmStatus(response.data);
            return;
        }

        // setup nilm_devices filter
        const nilm_devices = [{
            key: 'timeBasedAppliances',
            elements: ['dishWasher', 'washingMachine', 'dryer', 'oven']
        }];

        const new_amounts = this.filterNilmDeviceModelCount(response.data, nilm_devices);
        const old_amounts = this.filterNilmDeviceModelCount(old_nilm, nilm_devices);

        for (const key in new_amounts) {
            if (!new_amounts.hasOwnProperty(key)) {
                continue;
            }

            const parent_old = old_amounts[key];
            const parent_new = new_amounts[key];

            for (const inner_key in parent_new) {
                if (parent_old[inner_key] === 0) {
                    console.log('log', inner_key, parent_new[inner_key]);
                    console.log('log', inner_key, response.data['timeBasedAppliances'][inner_key].profileComplete);
                    if (parent_new[inner_key] !== 0 && response.data['timeBasedAppliances'][inner_key].profileComplete === false) {
                        const config = {
                            title: 'iONA hat ein neues Gerät erkannt',
                            info: 'Wie viele # gibt es in Ihrem Haushalt?',
                            icon: null,
                            amount: -1,
                            tag: inner_key,
                            appliance: null
                        };
                        switch (inner_key) {
                            case 'washingMachine':
                                config.icon = 'A.11';
                                config.appliance = 'Waschmaschinen';
                                config.amount = profile_data.data.Appliances['A.11'];
                                break;
                            case 'dishWasher':
                                config.icon = 'A.10';
                                config.appliance = 'Spülmaschinen';
                                config.amount = profile_data.data.Appliances['A.10'];
                                break;
                            case 'dryer':
                                config.icon = 'A.12';
                                config.appliance = 'Trockner';
                                config.amount = profile_data.data.Appliances['A.12'];
                                break;
                            case 'oven':
                                config.icon = 'A.04';
                                config.appliance = 'Öfen';
                                config.amount = profile_data.data.Appliances['A.04'];
                                break;
                        }

                        console.log('before visibility check');
                        if (document.visibilityState === 'visible') {
                            this.overlay_service.initialize(config);
                            this.overlay_service.showOverlay(true);
                            this._userService.updateActiveUserNilmStatusForAppliance(inner_key, new_amounts.timeBasedAppliances[inner_key]);
                        } else {
                            this.last_popup_shown = new Date();
                        }

                        return;
                    }
                }
            }
        }
    }

    /**
     * Neues Chart dem Dashboard zufügen
     *
     * @param id
     */
    addChart(id: number) {
        if (this.charts_available.filter((item: any) => item.id === id).length > 0) {
            this.charts_available = this.charts_available.filter((item: any) => item.id !== id);

            this.charts_selected.push({id: id});
            this._userService.setActiveUserCharts(this.charts_selected);
            this._notification.success('Der Bereich wurde der Übersicht hinzugefügt!');
        }
    }

    /**
     * Chart vom Dashboard entfernen
     * @param id
     */
    removeChart(id: string) {
        if (this.charts_selected.filter((item: any) => item.id === id).length > 0) {
            this.charts_selected = this.charts_selected.filter((item: any) => item.id !== id);

            this.charts_available.push({
                id: id
            });

            // this._apiService.setCharts(this.charts_selected);
            this._userService.setActiveUserCharts(this.charts_selected);

            this._analytics.eventTrack.next({
                action: 'dashboard_layout_change',
                properties: {
                    category: 'Dashboard',
                    label: 'type: delete; card: ' + this.charts.filter((item: any) => item.id === id)[0].name
                }
            });
        }
    }

    /**
     * Wird dieses Chart auf dem Dashboard angezeigt?
     * @param id
     */
    isSelectedChart(id: number): number {
        return this.charts_selected.filter((item: any) => item.id === id).length;
    }

    /**
     * Get Chart name
     * @param id
     */
    getChartName(id: number): string {
        let chart: any = this.charts.filter((item: any) => item.id === id);
        if (chart.length > 0) {
            return chart[0].name;
        } else {
            return null;
        }
    }

    /**
     * Show Changelog
     */
    showChangelog() {
        this.logs = true;
    }

    hideChangelog() {
        this.logs = false;
    }

    private onVisibilityChange(): void {
        console.log(this);
    }

}
