import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';

import {Chart} from 'angular-highcharts';

import {ApiService} from '../../services/api.service';
import {MockDataService} from '../../mock-data.service';
import {Subject, Subscription} from 'rxjs';
import * as moment from 'moment';
import {UserService} from '../../user.service';
import {RemainingTimeIndicatorComponent} from '../../remaining-time-indicator/remaining-time-indicator.component';

@Component({
    selector: 'live-details',
    templateUrl: './details.live.html',
    viewProviders: []
})

export class LiveDetails implements OnInit, OnDestroy, AfterViewInit {

    refresh: any = [];

    zoomlevels: any = [
        /*
        {
            name: '1 Monat',
            level: 6,
            interval: 60 * 60 * 24,
            offset: 60 * 24 * 30,
            resolution: 60 * 60 * 24 * 7,
            format: '%d.%m.%Y'
        },
        {
            name: '1 Woche',
            level: 5,
            interval: 60 * 60 * 24,
            offset: 60 * 24 * 7,
            resolution: 60 * 60 * 24,
            format: '%d.%m.%Y'
        },
        */
        {
            name: '1 Tag',
            hint: 'ø pro 15 Minuten',
            level: 4,
            interval: 15 * 60,
            offset: 60 * 24,
            resolution: 60 * 60 * 4,
            format: '%H:%M Uhr'
        },
        {
            name: '12 Stunden',
            hint: 'ø pro Minute',
            level: 3,
            interval: 60,
            offset: 60 * 12,
            resolution: 60 * 60,
            format: '%H:%M Uhr'
        },
        {
            name: '1 Stunde',
            hint: 'ø pro Minute',
            level: 2,
            interval: 60,
            offset: 60,
            resolution: 60 * 15,
            format: '%H:%M Uhr'
        },
        {
            name: '5 Minuten',
            hint: 'ø pro Sekunde',
            level: 1,
            interval: 1,
            offset: 5,
            resolution: 60,
            format: '%H:%M:%S Uhr'
        }
    ];

    zoomlevel: number = 1;
    position: number = 1;

    disabled: boolean = true;

    chart: any = {};

    current_consumption;

    status: any = {
        trend: 0,
        noZone: true,
        since: null
    };

    private current_demo_idx = 0;
    private mock_data_subscription: Subscription;

    /*
     * Happy hour stuff
     */
    userHasHappyHour = false;
    currentDataset = [];

    yesterdayHappyHourStart = null;
    yesterdayHappyHourEnd = null;
    todayHappyHourStart = null;
    todayHappyHourEnd = null;
    currentHappyHourStart = null;
    currentHappyHourEnd = null;
    private onUserHasHappyHour = new Subject<boolean>();

    @ViewChild('happyHourIndicator', {static: false}) hhIndicator: RemainingTimeIndicatorComponent;

    constructor(private _apiService: ApiService,
                private _mockService: MockDataService,
                private _userService: UserService) {
    }

    ngOnInit() {
        this.initializeChart();
        this.onUserHasHappyHour.subscribe(
            (value) => {
                if (value) {
                    this.userHasHappyHour = value;
                    this.getLiveConsumption();
                }
            }
        );
    }

    ngAfterViewInit(): void {
        // this.initializeChart();
        if (this._apiService.isDemoMode()) {
            this.initializeMockData();
            return;
        }

        this.initializeApiConnection();
    }

    ngOnDestroy() {
        if (this.refresh) {
            clearInterval(this.refresh);
        }
        if (this.mock_data_subscription !== null && this.mock_data_subscription !== undefined) {
            this.mock_data_subscription.unsubscribe();
        }
    }

    /**
     * Initialize demo mode mock data
     */
    private initializeMockData(): void {
        this.getMockConsumption();
        this.getMockStatus();

        this.refresh = setInterval(() => {
            this.getMockConsumption();
            this.getMockStatus();
        }, 10000);


        this._mockService.onCurrentConsumptionValue.subscribe(
            (value: { power: number, timestamp: number }) => {
                const current_consumption = value.power;
                const diff = current_consumption - Math.floor(current_consumption);
                if (diff < 0.5) {
                    this.current_consumption = Math.floor(current_consumption).toLocaleString('de-DE');
                } else {
                    this.current_consumption = Math.ceil(current_consumption).toLocaleString('de-DE');
                }
            }
        );
    }

    /**
     * Initialize live api connections
     */
    private initializeApiConnection(): void {
        if (this._userService.getActiveUserProvider().toLowerCase() === 'enviam') {
            this.determineUserHasHappyHour();
        }

        this.getLiveConsumption();
        this.getCurrentConsumption();
        this.getStatus();

        this.refresh = setInterval(() => {
            this.getLiveConsumption();
            this.getCurrentConsumption();
            this.getStatus();
        }, 10000);

    }

    /**
     *
     */
    private getMockConsumption() {
        const zoom: any = this.zoomlevels.filter((item: any) => item.level === this.zoomlevel);

        const offset: number = zoom[0].offset * this.position;
        const limit: number = offset - zoom[0].offset;
        const interval: number = zoom[0].interval;
        const level: number = zoom[0].level;

        this.mock_data_subscription = this._mockService.getLiveData(offset, level, limit, interval).subscribe(
            (values: any) => {
                if (values.length > 0) {
                    this.chart.removeSerie(0);
                    this.chart.addSerie(
                        {
                            name: null,
                            data: values.map((element) => {
                                return [
                                    new Date(element.timestamp).getTime(),
                                    element.power
                                ];
                            })
                        }
                    );

                    let chart: any = $('#chart-live-details').highcharts().hideLoading();

                    this.disabled = false;
                } else {
                    let chart: any = $('#chart-live-details').highcharts();
                    this.current_consumption = '0';
                    // this.chart.removeSerie(0);
                    chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                    this.disabled = false;
                }
            },
            () => {
                let chart: any = $('#chart-live-details').highcharts();
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                this.disabled = false;

            }
        );
    }

    /**
     * Daten von API für Livediagramm
     */
    private getLiveConsumption() {
        const zoom: any = this.zoomlevels.filter((item: any) => item.level === this.zoomlevel);

        const offset: number = zoom[0].offset * (this.position);
        const limit: number = offset - zoom[0].offset;
        const interval: number = zoom[0].interval;

        this._apiService.getLive(offset, limit, interval).subscribe(
            (response: any) => {
                this.handleLiveDataResponse(response);
            },
            (error) => {
                console.log('error', error);
                // let chart: any = $('#chart-live-details').highcharts();
                if (this.chart !== null && this.chart !== undefined) {
                    this.chart.ref.highcharts();
                    this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                }

                this.disabled = false;
            }
        );

        if (this.userHasHappyHour) {
            this.getHappyHourSchedule(offset);
        }
    }

    /**
     * Get current energy consumption-alert
     */
    private getCurrentConsumption() {
        this._apiService.getCurrentConsumption().subscribe(
            (response: any) => {
                if ('data' in response) {
                    if ('electricity' in response.data) {
                        if ('power' in response.data.electricity) {
                            this.current_consumption = response.data.electricity.power.toLocaleString('de-DE');
                        }
                    }
                }

            }
        );
    }


    /**
     * Aktueller Verbrauchsstatus/Zone (niedrig, mittel, hoch, seit wann)
     */
    private getStatus() {
        this._apiService.getHomeStateStatus().subscribe(
            (data: any) => {
                if (data.data.current_zone == 4) {
                    this.status.noZone = true;
                    return;
                }

                this.status.noZone = false;

                let date: any = new Date(data.data.since);
                let currentDate: any = new Date();

                // calculate difference
                let diffMs = (currentDate.getTime() - date.getTime()); // milliseconds between now & Christmas
                let diffHrs: number = Math.floor((diffMs % 86400000) / 3600000); // hours
                let diffMins: number = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

                let since: string = '';

                if (diffHrs > 0) {
                    if (diffHrs == 1) {
                        since = diffHrs + ' Stunde ';
                    } else {
                        since = diffHrs + ' Stunden ';
                    }
                }

                if (diffMins == 1) {
                    since += diffMins + ' Minute';
                } else {
                    since += diffMins + ' Minuten';
                }

                this.status = {
                    trend: data.data.current_zone,
                    since: since
                };
            },
            (response: any) => {
                this.status.noZone = true;
            }
        );
    }

    /**
     *
     */
    private getMockStatus(): void {
        this._mockService.getHomeStateStatus().subscribe(
            (data) => {
                this.status.noZone = false;

                let date: any = new Date(data.data.since);
                let currentDate: any = new Date();

                // calculate difference
                let diffMs = (currentDate.getTime() - date.getTime()); // milliseconds between now & Christmas
                let diffHrs: number = Math.floor((diffMs % 86400000) / 3600000); // hours
                let diffMins: number = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

                let since: string = '';

                if (diffHrs > 0) {
                    if (diffHrs == 1) {
                        since = diffHrs + ' Stunde ';
                    } else {
                        since = diffHrs + ' Stunden ';
                    }
                }

                if (diffMins == 1) {
                    since += diffMins + ' Minute';
                } else {
                    since += diffMins + ' Minuten';
                }

                this.status = {
                    trend: data.data.current_zone,
                    since: since
                };
            }
        );
    }

    /**
     * Request whether the user participates in the happy hour program
     */
    private determineUserHasHappyHour(): void {
        this._apiService.userParticipatesInHappyHour().subscribe(
            (response) => {

                if (response === null || response === undefined) {
                    console.log('undefined from response');
                    return;
                }

                if ('data' in response) {
                    const now = moment();

                    const parsed_response_data = response.data as Array<{ from_date: string, value: number }>;
                    // filter by elements with date earlier than today
                    const filtered = parsed_response_data.filter((element) => now >= moment(element.from_date, 'DD/MM/YYYY'));
                    const sorted = filtered.sort((a, b) => {
                        const a_from = moment(a.from_date, 'DD/MM/YYYY');
                        const b_from = moment(b.from_date, 'DD/MM/YYYY');
                        if (a_from.unix() > b_from.unix()) {
                            return -1;
                        } else if (a_from.unix() < b_from.unix()) {
                            return 1;
                        }
                        return 0;
                    });

                    if ('value' in sorted[0]) {
                        this.onUserHasHappyHour.next(sorted[0].value === 1);
                    }
                }

            },
            (error) => {
            }
        );
    }

    /**
     * Request the users happyhour schedule
     */
    private getHappyHourSchedule(offset: number): void {
        const date = moment().subtract(offset, 'minutes');
        // console.log('current offset date:', date.format('DD-MM-YYYY'));
        this._apiService.getHappyHourSchedule(date.year(), date.month() + 1).subscribe(
            (response) => {
                let today_schedule = null;
                let yesterday_schedule = null;
                const weekday = date.isoWeekday();
                // console.log('weekday', weekday);
                for (let i = 0; i < response.data.length; ++i) {
                    const element = response.data[i];
                    if (element.dayofweek === weekday) {
                        today_schedule = element;
                    }

                    if (element.dayofweek === 7) {
                        yesterday_schedule = response.data[0];
                    } else if (element.dayofweek === 1) {
                        yesterday_schedule = response.data[6];
                    } else {
                        yesterday_schedule = response.data[i - 1];
                    }

                }
                // console.log(today_schedule, yesterday_schedule);

                // update these values only if they were not set before
                if (this.currentHappyHourStart === null && this.currentHappyHourEnd === null) {
                    this.currentHappyHourStart = date.hour(today_schedule.starttime).minute(0).second(0).millisecond(0).toDate();
                    this.currentHappyHourEnd = date.hour(today_schedule.endtime).minute(0).second(0).millisecond(0).toDate();
                }
                this.hhIndicator.setStartTime(this.currentHappyHourStart, this.currentHappyHourEnd);

                this.todayHappyHourStart = date.hour(today_schedule.starttime).minute(0).second(0).millisecond(0).toDate();
                this.todayHappyHourEnd = date.hour(today_schedule.endtime).minute(0).second(0).millisecond(0).toDate();

                const yesterday = moment(date).add(1, 'day');
                this.yesterdayHappyHourStart = yesterday.hour(yesterday_schedule.starttime).minute(0).second(0).millisecond(0).toDate();
                this.yesterdayHappyHourEnd = yesterday.hour(yesterday_schedule.endtime).minute(0).second(0).millisecond(0).toDate();

                // console.log('today start', this.todayHappyHourStart);
                // console.log('yesterday start', this.yesterdayHappyHourStart);
                // console.log('=================================');

                if (this.chart.ref !== null && this.chart.ref !== undefined) {
                    this.chart.ref.update({plotOptions: {area: {zones: this.calculateNewZones()}}});
                }
            }
        );
    }

    /**
     * Zoom in
     */
    zoomIn() {
        this.setZoom(this.zoomlevel - 1);
    }

    /**
     * Zoom out
     */
    zoomOut() {
        this.setZoom(this.zoomlevel + 1);
    }

    /**
     * Set specific zoom level
     */
    setZoom(level: number) {
        if ((level !== this.zoomlevel) && (!this.disabled)) {
            let zoom: any = this.zoomlevels.filter((item: any) => item.level === level);

            if (zoom.length > 0) {
                this.zoomlevel = level;
                this.position = 1;

                this.chart.ref.update({
                    xAxis: {
                        tickInterval: zoom[0].resolution * 1000
                    },
                    tooltip: {
                        xDateFormat: zoom[0].format
                    },
                    series: [
                        {
                            name: null,
                            data: []
                        }
                    ]
                });

                this.resetChart();
            }
        }
    }

    positionForward() {
        if ((this.position > 1) && (!this.disabled)) {
            this.position--;

            this.resetChart();
        }
    }

    positionBack() {
        if (!this.disabled) {
            this.position++;

            this.resetChart();
        }
    }

    positionNow() {
        if (!this.disabled) {
            this.position = 1;

            this.resetChart();
        }
    }

    resetChart() {
        let chart: any = $('#chart-live-details').highcharts();
        chart.showLoading();

        this.disabled = true;

        if (this._apiService.isDemoMode()) {
            this.current_demo_idx = 0;
            this.getMockConsumption();
            return;
        }

        this.getLiveConsumption();
    }


    initializeChart(): void {
        this.chart = new Chart({
            chart: {
                type: 'area',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 40, 0]
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                tickInterval: 60 * 1000,
                dateTimeLabelFormats: {
                    millisecond: '%H:%M',
                    second: '%H:%M',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%H:%M<br/>%d.%m',
                    week: '%d.%m',
                    month: '%m.%Y',
                    year: '%Y'
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                },
                crosshair: {
                    width: 1,
                    color: 'rgba(0,0,0,0.6)',
                    zIndex: 3
                },
                minPadding: 0,
                maxPadding: 0
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    align: 'right',
                    x: 60,
                    y: 15,
                    step: 2,
                    formatter: function() {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE') + ' W';
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontSize: '13px'
                    }
                },
                gridZIndex: 10,
                gridLineColor: 'rgba(0,0,0,0.20)'
            },
            tooltip: {
                xDateFormat: '%H:%M:%S Uhr',
                hideDelay: 0,
                animation: false,
                shadow: false,
                positioner: function(boxWidth: number, boxHeight: number, point: any) {
                    if ((point.plotX + this.chart.plotLeft + boxWidth) > this.chart.plotWidth) {
                        return {
                            x: (point.plotX + this.chart.plotLeft) - boxWidth - 10,
                            y: 120
                        };
                    } else {
                        return {
                            x: point.plotX + this.chart.plotLeft + 10,
                            y: 120
                        };
                    }
                },
                useHTML: true,
                headerFormat: '<div class="large">{point.key}</div>',
                pointFormat: '<div class="small">{point.y:,.0f} Watt</div>',
                style: {
                    color: '#143C8C',
                    zIndex: 15,
                },
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                borderWidth: 0,
                borderRadius: 0
            },
            plotOptions: {
                series: {
                    showInLegend: false,
                    animation: false,
                    marker: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                area: {
                    fillOpacity: 1,
                    lineWidth: 0,
                    marker: {
                        enabled: false
                    },
                    zoneAxis: 'x',
                    zones: [
                        {value: 0},
                        {color: 'rgb(0, 170, 225)'},
                    ]
                }
            },
            series: [
                {
                    name: null,
                    data: []
                }
            ],
            credits: {
                enabled: false
            }
        });
    }

    private calculateNewZones(): any {
        return [
            {color: 'rgba(0, 170, 225,1)'},
            {value: this.todayHappyHourStart.getTime()},
            {color: 'rgba(20, 60, 140,1)'},
            {value: this.todayHappyHourEnd.getTime()},
            {color: 'rgba(0, 170, 225, 1)'},
            {value: this.yesterdayHappyHourStart.getTime()},
            {color: 'rgba(20, 60, 140,1)'},
            {value: this.yesterdayHappyHourEnd.getTime()},
            {color: 'rgba(0, 170, 225,1)'},
            {value: this.currentHappyHourStart.getTime()},
            {color: 'rgba(20, 60, 140,1)'},
            {value: this.currentHappyHourEnd.getTime()},
            {color: 'rgba(0, 170, 225, 1)'},
        ];
    }

    /**
     * Handle live data response from API.
     * @param response
     */
    private handleLiveDataResponse(response: any): void {
        if (response.status === 'ok' && 'data' in response) {
            const data = response.data.results;
            this.currentDataset = data;

            // calculate max power value
            const max = this.currentDataset.reduce((prev, current) => {
                return (prev.power > current.power) ? prev : current;
            });

            const background = [];
            for (const element of this.currentDataset) {
                const d = new Date(element.timestamp);
                if (d >= this.todayHappyHourStart && d <= this.todayHappyHourEnd) {
                    background.push({timestamp: element.timestamp, power: max.power});
                } else {
                    background.push({timestamp: element.timestamp, power: 0});
                }
            }

            this.chart.removeSerie(0);
            this.chart.removeSerie(1);

            // add actual graph
            this.chart.addSerie(
                {
                    name: null,
                    zIndex: 5,
                    data: data.map((element) => {
                        return [
                            new Date(element.timestamp).getTime(),
                            element.power
                        ];
                    })
                }
            );

            const chart: any = $('#chart-live-details').highcharts().hideLoading();
            this.disabled = false;
        }
    }


}
