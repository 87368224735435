import {of, Subject, throwError} from 'rxjs';
import {Observable} from 'rxjs/Observable';

import {map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {constants} from './constants';
import * as moment from 'moment';

@Injectable(
    {providedIn: 'root'}
)
export class MockDataService {

    onCurrentConsumptionValue = new Subject();

    private timestamp_start = new Date();

    private test: boolean = false;
    // private test: boolean = true;

    // private online_disabled: boolean = true;
    private online_disabled: boolean = false;

    private plugOff: boolean = false;
    // private plugOff: boolean = true;

    // private userHasPlug: boolean = false;
    private userHasPlug: boolean = true;

    private power_data = {
        power_5_min: null,
        power_1_hour: null,
        power_12_hours: null,
        power_24_hours: null,
    };

    private consumption_data = {
        hours: null,
        days: null,
        months: null,
    };

    constructor(private http: HttpClient) {
    }

    /**
     * Get live data from mock files
     * @param offset
     * @param limit
     * @param interval
     */
    public getLiveData(offset: number, level: number, limit: number, interval: number): Observable<any> {
        let dataset_file = '';
        let dataset;
        switch (level) {
            case 1:
                dataset_file = constants.demo.files.power_5_seconds;
                if (this.power_data.power_5_min !== null) {
                    dataset = this.power_data.power_5_min;
                }
                break;
            case 2:
                dataset_file = constants.demo.files.power_1_minute;
                if (this.power_data.power_1_hour !== null) {
                    dataset = this.power_data.power_1_hour;
                }
                break;
            case (3):
                dataset_file = constants.demo.files.power_1_minute;
                if (this.power_data.power_12_hours !== null) {
                    dataset = this.power_data.power_12_hours;
                }
                break;
            case (4):
                dataset_file = constants.demo.files.power_15_minutes;
                if (this.power_data.power_24_hours !== null) {
                    dataset = this.power_data.power_24_hours;
                }
                break;
        }

        if (dataset) {
            return of(dataset).pipe(
                map((value) => {
                    return this.filterByTime(value, offset, limit);
                }),
                catchError((error) => {
                    return throwError(error);
                }));
        }

        return this.http.get(`assets/data/demo/${dataset_file}.json`).pipe(
            map((value: any) => {
                const data_aligned = this.mapValues(value, offset, interval, level);
                switch (offset) {
                    case 5:
                        this.power_data.power_5_min = data_aligned;
                        break;
                    case 60:
                        this.power_data.power_1_hour = data_aligned;
                        break;
                    case (60 * 12):
                        this.power_data.power_12_hours = data_aligned;
                        break;
                    case (60 * 24):
                        this.power_data.power_24_hours = data_aligned;
                        break;
                }
                const filtered = this.filterByTime(data_aligned, offset, limit);
                return filtered;
            }),
            catchError((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return throwError(error);
            }),);
    }


    /**
     * Get live data from mock files
     * @param offset
     * @param limit
     * @param interval
     */
    public getCurrentConsumption2(): Observable<any> {
        const offset: number = 5;
        const limit: number = 0;
        const interval: number = 1;
        const level: number = 1;

        let dataset = null;
        const dataset_file = constants.demo.files.power_5_seconds;
        if (this.power_data.power_5_min !== null) {
            dataset = this.power_data.power_5_min;
        }

        if (dataset) {
            return of(dataset).pipe(
                map((value) => {
                    const f = this.filterByTime(value, offset, limit);
                    this.onCurrentConsumptionValue.next(f[f.length - 1]);
                    return f[f.length - 1];

                }),
                catchError((error) => {
                    return throwError(error);
                }));
        }

        return this.http.get(`assets/data/demo/${dataset_file}.json`).pipe(
            map((value: any) => {
                const data_aligned = this.mapValues(value, offset, interval, level);
                if (!this.power_data.power_5_min) {
                    this.power_data.power_5_min = data_aligned;
                }

                const filtered = this.filterByTime(data_aligned, offset, limit);
                return filtered[filtered.length - 1];
            }),
            catchError((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return throwError(error);
            }),);
    }

    /**
     * Get the current consumption-alert status
     */
    public getHomeStateStatus(): Observable<any> {
        return this.http.get(`assets/data/demo/${constants.demo.files.home_state_current}.json`)
            .map((value) => value)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }

    public getHomeStateConfig(): Observable<any> {
        return this.http.get(`assets/data/demo/${constants.demo.files.home_state_config}.json`).pipe(
            map((value) => value),
            catchError((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            })
        );
    }

    /**
     *
     * @param offset
     */
    public getDayConsumption(offset: number): Observable<any> {

        let dataset: string = '';
        switch (offset) {
            case 7:
                dataset = constants.demo.files.consumption_hours_last_week;
                break;
            case 0:
                dataset = constants.demo.files.consumption_hours;
                break;
        }

        return this.http.get(`assets/data/demo/${dataset}.json`)
            .map((values) => {
                if (offset === 7) {
                    return values;
                }

                const new_dataset = [];
                const today: any = new Date();
                let counter = 0;
                for (const set of values['data']) {
                    // const date = new Date(set.timestamp);
                    const date = new Date(today);
                    date.setHours(counter);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    if (((today.getMinutes() <= 29) && (date.getHours() <= today.getHours())) || ((today.getMinutes() >= 30) && (date.getHours() <= (today.getHours() + 1)))) {
                        set.timestamp = date;
                        new_dataset.push(set);
                    }

                    // console.log(date);
                    ++counter;
                }

                return {status: 'ok', data: new_dataset};
            })
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }


    /**
     * Get current electrical appliances
     */
    public getElectricalAppliances(offset: number): Observable<any> {
        return this.http.get(`assets/data/demo/${constants.demo.files.disagregation_history}.json`)
            .map((value) => {
                const dataset = value;
                return {status: 'ok', data: dataset['data'][dataset['data'].length - offset - 1]};
            })
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }

    /**
     *
     * @param month
     * @param offset This describes the used offset in 12 month steps
     */
    public getElectricalAppliancesForMonth(month: number, offset: number): Observable<any> {

        return this.http.get(`assets/data/demo/${constants.demo.files.disagregation_history}.json`)
            .map((value) => {
                const dataset = value['data'].reverse();
                const current_month = new Date().getMonth();
                const month_idx = month + (offset * 12) - 1;
                // console.log(month_idx);

                if (offset == 0 || month <= current_month) {
                    return {status: 'ok', data: dataset[month_idx]};
                }

                return {status: 'ok', data: dataset[month_idx]};
            })
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });


    }

    /**
     * Load smart meter information data
     */
    public getInitialize(): Observable<any> {
        let filename;
        if (this.test) {
            filename = constants.demo.files.initialization_test;
        } else {
            filename = constants.demo.files.initialization;
        }

        return this.http.get(`assets/data/demo/${filename}.json`)
            .map((values) => values)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return throwError(error);
            });
    }


    public getMeterStatus(): Observable<any> {
        return this.http.get(`assets/data/demo/${constants.demo.files.meter_status}.json`)
            .map((values) => values)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }


    /**
     * Get user profile data
     */
    public getProfile(): Observable<any> {
        return this.http.get(`assets/data/demo/${constants.demo.files.profile}.json`)
            .map((values) => values)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }

    /**
     *
     */
    public getProfileAttributes(): Observable<any> {
        return this.http.get(`assets/data/demo/${constants.demo.files.profile_attributes}.json`)
            .map((values) => values)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }

    /**
     *
     */
    public getCurrentConsumption(): Observable<any> {
        return this.http.get(`assets/data/demo/${constants.demo.files.instantaneous}.json`).pipe(
            map((values) => {
                return values;
            }),
            catchError((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            })
        );
    }

    /**
     * Return the consumption-alert for a specific date
     */
    public getConsumptionForDate(date: Date): Observable<any> {
        return this.http.get(`assets/data/demo/${constants.demo.files.consumption}.json`)
            .map((values) => {
                let data = values['data'].reverse();
                for (let i = 0; i < data.length; ++i) {
                    const start_time_cpy = new Date(this.timestamp_start);
                    const new_date = new Date(start_time_cpy.setDate(start_time_cpy.getDate() - i));
                    data[i].timestamp = new_date;
                }

                const date_cpy = new Date(date);
                date_cpy.setHours(0, 0, 0, 0);

                const filtered = data.filter((element) => {
                    const element_date = new Date(element.timestamp);
                    element_date.setHours(0, 0, 0, 0);

                    const day_match = element_date.getDate() == date.getDate();
                    const mon_match = element_date.getMonth() == date.getMonth();
                    const year_match = element_date.getFullYear() == date.getFullYear();

                    return day_match && mon_match && year_match;
                });

                return {status: 'ok', data: filtered};
            })
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);

            });
    }

    /**
     *
     * @param filter
     * @param start_date
     * @param end_date
     */
    public getConsumptionByDate(filter: string, start_date: Date, end_date: Date): Observable<any> {
        let dataset_file: string = '';
        let dataset_loaded: boolean = false;
        let dataset;
        switch (filter) {
            case 'months':
                dataset_file = constants.demo.files.consumption_months;
                dataset_loaded = this.consumption_data.months !== null;
                if (dataset_loaded) {
                    dataset = this.consumption_data.months;
                }
                break;
            case 'days':
                dataset_file = constants.demo.files.consumption_days;
                dataset_loaded = this.consumption_data.days !== null;
                if (dataset_loaded) {
                    dataset = this.consumption_data.days;
                }
                break;
            case 'hours':
                dataset_file = constants.demo.files.consumption_hours;
                dataset_loaded = this.consumption_data.hours !== null;
                if (dataset_loaded) {
                    dataset = this.consumption_data.hours;
                }
                break;
        }


        if (dataset_loaded) {
            const dataset_filtered = this.getComparison(dataset, filter, start_date, end_date);
            return Observable.of(dataset_filtered);
        }

        return this.http.get(`assets/data/demo/${dataset_file}.json`)
            .map((values) => {
                const data = values['data'].reverse();

                // pre process datasets to feature the current time as starting point
                switch (filter) {
                    case 'months':
                        for (let i = 0; i < data.length; ++i) {
                            const start_time_cpy = new Date(this.timestamp_start);
                            const new_date = new Date(start_time_cpy.setMonth(start_time_cpy.getMonth() - i));
                            data[i].timestamp = new_date;
                        }
                        this.consumption_data.months = data;
                        break;
                    case 'days':
                        for (let i = 0; i < data.length; ++i) {
                            const start_time_cpy = new Date(this.timestamp_start);
                            const new_date = new Date(start_time_cpy.setDate(start_time_cpy.getDate() - i));
                            data[i].timestamp = new_date;
                        }
                        this.consumption_data.days = data;
                        break;
                    case 'hours':
                        break;
                }
                return this.getComparison(data, filter, start_date, end_date);
            })
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }

    private getComparison(data: any, filter: string, date_start: Date, date_end: Date,) {
        const filtered = data.filter((element) => {

            // if the dates match e.g when selecting a single day
            switch (filter) {
                case 'days':

                    if (date_start == date_end) {
                        const element_date = new Date(element.timestamp);

                        const day_match = element_date.getDate() == date_start.getDate();
                        const mon_match = element_date.getMonth() == date_start.getMonth();
                        const year_match = element_date.getFullYear() == date_start.getFullYear();

                        return day_match && mon_match && year_match;
                    }
                    break;
                case 'months':
                    if (date_start == date_end) {
                        const element_date = new Date(element.timestamp);

                        // const day_match = element_date.getDate() == date_start.getDate();
                        const mon_match = element_date.getMonth() == date_start.getMonth();
                        const year_match = element_date.getFullYear() == date_start.getFullYear();

                        return mon_match && year_match;
                    }
                    break;
                default:
                    break;
            }

            return element.timestamp >= date_start && element.timestamp <= date_end;
        });
        return {status: 'ok', data: filtered.reverse()};
    }


    getMonthlyComparison(): Observable<any> {
        return this.http.get(`assets/data/demo/${constants.demo.files.consumption_months}.json`)
            .map((values) => {
                const data = values['data'];
                return {status: 'ok', data: [data[data.length - 1], data[data.length - 2]]};
            })
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }

    getBillPrediction(): Observable<any> {

        let file = constants.demo.files.bill_prediction;
        if (this.test) {
            file = constants.demo.files.bill_prediction_test;
        }

        return this.http.get(`assets/data/demo/${file}.json`)
            .map((values) => values)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }


    getNILMStatus(): Observable<any> {
        let filename: string = constants.demo.files.nilm_status;
        if (this.test) {
            filename = constants.demo.files.nilm_status_test;
        }
        return this.http.get(`assets/data/demo/${filename}.json`)
            .map((values) => values)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }

    getRegistrationOnline(): Observable<any> {
        let filename: string = constants.demo.files.nilm_status;
        if (this.online_disabled) {
            filename = '';
        } else {
            if (this.test) {
                filename = constants.demo.files.registration_online_test;
            }
        }
        console.log('requesting', filename);
        return this.http.get(`assets/data/demo/${filename}.json`)
            .map((values) => values)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return throwError(error);
            });
    }

    getRegistrationModel(): Observable<any> {
        let filename: string = constants.demo.files.registration_model;
        if (!this.userHasPlug) {
            filename = constants.demo.files.registration_model_test;
        }
        return this.http.get(`assets/data/demo/${filename}.json`)
            .map((values) => values)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }

    getPlugStatus(): Observable<any> {
        let filename: string = constants.demo.files.plug_relay;
        if (this.plugOff) {
            filename = constants.demo.files.plug_relay_test;
        }
        return this.http.get(`assets/data/demo/${filename}.json`)
            .map((values) => values)
            .catch((error) => {
                if (error.status === 401) {
                    console.log('MockDataService error: ', error);
                }
                return Observable.throw(error);
            });
    }

    /**
     * Map values based on the current time
     * @param response
     * @param interval
     */
    private mapValues(response, offset, interval, position): any {
        let data = response['data'].results as any[];
        data = data.reverse();

        let num_history_steps = 0;
        switch (position) {
            case 1:
                num_history_steps = ~~((data.length - 300) / 300);
                break;
            case 2:
                num_history_steps = ~~(data.length / 60) - 5;
                break;
            case 3:
                num_history_steps = ~~(data.length / 720);
                break;
            case 4:
                num_history_steps = ~~(data.length / 144) + 1;
                break;
        }

        const start_time_cpy = new Date(this.timestamp_start);
        start_time_cpy.setMinutes(start_time_cpy.getMinutes() - (num_history_steps * offset));

        data.forEach((el, idx) => {
            const new_date = start_time_cpy.setSeconds(start_time_cpy.getSeconds() + interval);
            el.timestamp = new_date;
        });

        return {status: 'ok', data: {results: data, ranges: response.ranges}};

    }

    /**
     * Filter Values by a given timeframe
     * @param data
     * @param offset
     * @param limit
     */
    private filterByTime(data, offset, limit): any[] {
        const timestamp_start = new Date();
        timestamp_start.setMinutes(timestamp_start.getMinutes() - offset);

        const timestamp_end = new Date();
        timestamp_end.setMinutes(timestamp_end.getMinutes() - limit);

        const filtered = data['data'].results.filter((value) => {
            const element_date = new Date(value.timestamp);
            // return value.timestamp >= timestamp_start && value.timestamp <= timestamp_end;
            return element_date >= timestamp_start && element_date <= timestamp_end;
        });

        return filtered;
    }


}
