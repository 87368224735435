import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';

import {ToastrService} from 'ngx-toastr';
import {Angulartics2} from 'angulartics2';

import {ApiService} from '../services/api.service';
import {Globals} from '../services/globals.service';
import {UserService} from '../user.service';
import {AnimationItem} from 'lottie-web';
import {constants} from '../constants';

@Component({
    selector: 'iona-app',
    templateUrl: './register.component.html',
    styleUrls: ['register.component.scss'],
    viewProviders: [ApiService],
    providers: [Globals]
})
export class RegisterComponent implements OnInit, OnDestroy {
    refresh: any = [];
    checkMeterStatusInterval: any = [];

    onboardingSteps = {
        accountCreation: 'account-creation',
        passwordEntry: 'password-entry',
        deviceSelection: 'device-selection',
        accountSetup: 'account-setup',
        devices: {
            box: {
                powerConnect: 'box-ac-connect',
                lanConnect: 'box-lan-connect',
                macEntry: 'box-mac-entry',
                macEntry2: 'box-mac-entry-2',
                connecting: 'box-connecting',
                notFoundError: 'box-not-found',
                connectionError: 'box-connection-error'
            },
            plug: {
                noteMac: 'plug-ac-note-mac',
                powerConnect: 'plug-ac-connect',
                appSelect: 'plug-app-select',
                wpsConnect: 'plug-wps-connect',
                wpsRouter: 'plug-wps-router',
                wifiConnected: 'plug-wifi-connected',
                macEntry: 'plug-mac-entry',
                macEntry2: 'plug-mac-entry-2',
                connecting: 'plug-connecting'
            }
        }
    };

    currentStep: string = this.onboardingSteps.accountCreation;
    // currentStep: string = this.onboardingSteps.deviceSelection;

    tries: number = 0;  // Anzahl fehlgeschlagener Versuche das Meter zu Verbinden
    meterStatus: number = 0;    // Aktueller Meter-Verbindungsstatus

    device = 'box';
    deviceTitle: string;
    macPlaceholder: string = ' ____ : ____ : ____ ';

    statusTries: any = {    // Anzahl fehlgeschlagener Meter-Verbindungsversuche nach aktuellem Status gegliedert
        step0: 0,
        step1: 0,
        step2: 0,
    };

    statusError: any = {    // Fehlertext bei Verbindungs-Fehler bei zu vielen Versuchen. Ist je nach aktuellem Status unterschiedlich
        title: '',
        text: ''
    };

    connect: any = {
        type: null,
        tries: 0
    };

    user: any = {   // Nutzerdaten
        voucher: null,
        email: null,
        password: null,
        mac: null
    };

    format: any = { // gefordertes Format der MAC
        mac: [
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/
        ]
    };

    input: any = { // Inhalt Input-Feld der MAC
        mac: ''
    };

    // animation
    lottieConfig2 = {
        path: 'assets/anim/onboarding_meter_animation.json',
        autoplay: false,
        name: 'Smart Meter Animation',
        loop: true,
        renderer: 'canvas'
    };
    animationReady: boolean = false;
    anim: AnimationItem;

    constructor(private _titleService: Title,
                private _router: Router,
                private _route: ActivatedRoute,
                private _notification: ToastrService,
                private _analytics: Angulartics2,
                public _apiService: ApiService,
                private _globals: Globals,
                private _userService: UserService) {
    }

    ngOnInit() {
        this._titleService.setTitle('Registrieren | iONA');

        // get previously stored device
        const stored_device = this._userService.getUserDevice();
        if (stored_device !== null && stored_device !== undefined) {
            if (stored_device === constants.application.devices.box) {
                this.device = 'box';
                this.deviceTitle = 'iONA-Box';
            } else if (stored_device === constants.application.devices.plug) {
                this.device = 'plug';
                this.deviceTitle = 'PowerChecker';
            }
        }

        this.refresh = setInterval(() => {
            if (this.currentStep === 'Z') {
                // Der Onlinestatus kann bis zu 2h dauern. Daher sollte der Step "Z" gar nicht mehr erreicht werden, sondern direkt zum Dashboard weiter geleitet werden
                this.checkOnline();
            }
        }, 10000);

        // Directly Jump to Onboarding?
        this._route.queryParams.subscribe(params => {
            if (typeof params['jumpToOnboarding'] !== 'undefined' && params['jumpToOnboarding']) {
                if (this._userService.getActiveUserName() !== null) {
                    this.setStep(this.onboardingSteps.devices.box.macEntry);
                } else {
                    this._router.navigate(['/registrieren']);
                }
            }

            if (typeof params['jumpToMeterstate'] !== 'undefined' && params['jumpToMeterstate']) {
                if (this._userService.getActiveUserName() !== null) {
                    if (this.device === 'plug') {
                        this.setStep(this.onboardingSteps.devices.plug.connecting);
                    } else {
                        this.setStep(this.onboardingSteps.devices.box.connecting);
                    }
                    // this.setStep('C');
                    this.checkMeterStatus();
                } else {
                    this._router.navigate(['/registrieren']);
                }
            }

        });

        // MAC Id bereits im Local Storage gesetzt?
        if (this._globals.getIonaMacId() !== null && this._globals.getIonaMacId().length > 0) {
            this.input.mac = this._globals.getIonaMacId();
        }

    }

    ngOnDestroy() {
        clearInterval(this.refresh);
        clearInterval(this.checkMeterStatusInterval);
    }

    /**
     *
     * Voucher validieren
     *
     * @param voucher
     * @param email
     */
    validateVoucher(voucher: string, email: string) {
        this._apiService.validateVoucher(voucher, email).subscribe(
            () => {
                // Erfolg -> Email und Voucher im User setzen und weiter gehts zum nächsten Step
                this.user.voucher = voucher;
                this.user.email = email;

                this.setStep(this.onboardingSteps.passwordEntry);

                this._analytics.eventTrack.next({
                    action: 'onboarding_voucher_code_enter',
                    properties: {
                        category: 'Onboarding',
                        label: 'true'
                    }
                });
            },
            (response: any) => {
                if (response._body) {
                    let error: any = JSON.parse(response._body);
                    switch (error.error.code) {
                        case 106: {
                            this._notification.error('Der eingegebene iONA-Code ist uns nicht bekannt!');

                            break;
                        }
                        default: {
                            this._notification.error('Validierung des iONA-Code fehlgeschlagen!');
                        }
                    }

                    this._analytics.eventTrack.next({
                        action: 'onboarding_voucher_code_enter',
                        properties: {
                            category: 'Onboarding',
                            label: 'false'
                        }
                    });
                } else {
                    this._notification.error('Bitte geben Sie E-Mail-Adresse und iONA-Code ein!');
                }
            }
        );
    }

    /**
     * Nutzer registrieren
     *
     * @param password
     * @param confirm
     */
    registerUser(password: string, confirm: string) {
        console.log(`passwords match: ${password === confirm}`);

        if (password !== confirm) {
            this._notification.error('Ihre Passwörter stimmen nicht überein!');
            return;
        }

        let user = {
            email_address: this.user.email,
            pincode: password,
            voucher_code: this.user.voucher
        };

        this._apiService.registerUser(user).subscribe(
            (response) => {
                console.log('response registerUser', response);
                this.user.password = password;
                this._apiService.loginUser(this.user.email, this.user.password, true);

                this._apiService.onLoggedIn.subscribe(() =>
                    this._apiService.getRegistrationModel().subscribe(
                        (model_response: any) => {
                            if (model_response.status === 'ok') {
                                if ('model_identifier' in model_response.data) {
                                    console.log('model identifier', model_response.data);
                                    // apparently its pretty hard to persistenlty assing a device id....
                                    switch (model_response.data.model_identifier) {
                                        case constants.application.devices.plug:
                                            this.selectDevice('plug');
                                            break;
                                        default:
                                            this.selectDevice('box');
                                    }
                                }
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    )
                );
            },
            (err: any) => {
                console.log(err);
                if ('error' in err) {
                    if ('error' in err.error) {
                        if (err.error.error.code === 264) {
                            const msg = err.error.error.message;
                            if (msg.includes('MAX length')) {
                                this._notification.error('Das gewählte Passwort ist zu lang. Bitte verwenden Sie maximal 16 Zeichen.', 'Passwort ungültig', {timeOut: 6000});
                            } else if (msg.includes('Special characters')) {
                                this._notification.error('Das gewählte Passwort enthält unzulässige Sonderzeichen. Bitte verwenden Sie nur Buchstaben und Zahlen, sowie die folgenden Sonderzeichen: ! \" ( ) = [ ] { } ? \\ + * ~ # , ; . - _ Umlaute und ß sind nicht möglich.', 'Passwort ungültig', {timeOut: 6000});
                            }
                        }
                        if (err.error.error.code === 102) {
                            const msg = err.error.error.message;
                            if (msg.includes('already active on')) {
                                this._notification.error('Sie haben bereits ein Konto erstellt.', 'Fehler', {timeOut: 6000});
                            }

                        }
                    }
                }
                if (err._body) {
                    let error: any = JSON.parse(err._body);
                    switch (error.error.code) {
                        case 102: {
                            this._notification.info('Sie sind bereits registriert!');
                            this.setStep(this.onboardingSteps.devices.box.powerConnect);

                            break;
                        }
                        case 264: {
                            console.log('CASE 264 ');
                        }
                        default: {
                            this._notification.error('Registrierung fehlgeschlagen!');
                        }
                    }
                } else {
                    this._notification.error('Registrierung fehlgeschlagen!');
                }
            }
        );
    }

    /**
     * Meter registrieren
     * @param mac
     * @param throwError
     */
    registerDevice(mac: string, throwError: boolean = true) {
        this.user.mac = null;

        // mac im LocalStorage speichern
        // this._globals.setIonaMacId(mac);

        if (mac.indexOf('_') < 0) {
            let newMac = mac.replace(/:/g, '');
            let macPart1 = newMac.substring(0, 6);
            let macPart2 = newMac.substring(6);
            newMac = macPart1 + '0000' + macPart2;

            this._apiService.registerDevice(newMac).subscribe(
                (response: any) => {
                    this.registerDeviceSuccess(newMac);
                    if (this.device === 'plug') {
                        this.setStep(this.onboardingSteps.devices.plug.connecting);
                    } else {
                        this.setStep(this.onboardingSteps.devices.box.connecting);
                    }
                },
                (error: any) => {
                    if (error.error.error.code === 102) {
                        console.log('register device error:', error.error.error.code, error.error.error.message);
                        if (this.device === 'plug') {
                            this.setStep(this.onboardingSteps.devices.plug.connecting);
                        } else {
                            this.setStep(this.onboardingSteps.devices.box.connecting);
                        }
                        this.registerDeviceSuccess(newMac);
                    } else if (error.error.error.code === 110) {
                        console.log('register device error:', error.error.error.code, error.error.error.message);
                        this._notification.error('Registrierung fehlgeschlagen');
                    } else if (error.error.error.code === 999) {
                        console.log('register device error:', error.error.error.code, error.error.error.message);
                        this._notification.error('Registrierung fehlgeschlagen');
                    } else if ('_body' in error) {
                        let jsonError;

                        try {
                            jsonError = JSON.parse(error._body);
                        } catch (e) {
                            if (throwError) {
                                this.registerDeviceError();
                            }
                        }
                    } else if (throwError) {
                        this.registerDeviceError();
                    }
                },
            );
        }
    }

    registerDeviceSuccess(newMac: any) {
        this.user.mac = newMac;
        this.checkMeterStatus();

        this._analytics.eventTrack.next({
            action: 'onboarding_finish',
            properties: {
                category: 'Onboarding',
                label: 'mode: ' + this.connect.type + '; gateway_id: ' + this.user.mac
            }
        });

        this._apiService.optInDevice().subscribe(
            (response: any) => {
                // console.log('opt in succeeded', response);
            },
            (e) => {
                console.log('opt in failed with:', e.error.error.code, e.error.error.message);
            }
        );
    }

    registerDeviceError() {
        this.connect.tries++;

        switch (this.connect.type) {
            case 'LAN': {
                // Local Storage MAC entfernen
                this.input.mac = '';
                this._globals.resetIonaMacId();

                this.setStep(this.onboardingSteps.devices.box.notFoundError);

                break;
            }
            case 'AP': {
                if (this.connect.tries >= 3) {
                    this.setStep('Ü');
                } else {
                    this.setStep(this.onboardingSteps.devices.box.connectionError);
                }

                break;
            }
        }
    }

    handleAnimation(anim: any): void {
        this.anim = anim;
        this.anim.stop();

        setTimeout(() => {
            this.playAnimationSegment(0);
            this.animationReady = true;
            this.checkMeterStatus();
        }, 100);
    }

    playAnimationSegment(step: number): void {
        if (this.anim === null || this.anim === undefined) {
            return;
        }
        if (!this.animationReady) {
            return;
        }

        if (step === 0) {
            setTimeout(() => {
                this.anim.playSegments([0, 85], true);
            }, 150);
        } else if (step === 1) {
            setTimeout(() => {
                this.anim.playSegments([205, 318], true);
            }, 150);
        } else if (step === 2) {
            setTimeout(() => {
                this.anim.playSegments([350, 508], true);
            }, 150);
        } else if (step === 3) {
            setTimeout(() => {
                this.anim.playSegments([655, 655], true);
            }, 150);
        }
    }

    /**
     * Regelmäßig den aktuellen Status der Meter-Registrierung abfragen
     */
    checkMeterStatus() {
        this.meterStatusCall();
        this.checkMeterStatusInterval = setInterval(() => {
            this.meterStatusCall();
        }, 5000);
    }

    /**
     * Je nach aktuellem Status eine bestimmte Animation anzeigen und die Versuche der Registrierung mitzählen
     */
    meterStatusCall() {
        this._apiService.getDeviceStatus().subscribe(
            (response: any) => {
                if ('data' in response) {
                    if ('current_status' in response.data) {
                        switch (response.data.current_status.toUpperCase()) {
                            case 'READY_FOR_METER_INCLUSION':
                                if (this.animationReady) {
                                    if (this.meterStatus != 1) {
                                        this.playAnimationSegment(1);
                                        this.meterStatus = 1;
                                        this._globals.resetIsMeterConnected();
                                    }
                                }

                                break;
                            case 'CONTACT_WITH_METER':
                                if (this.animationReady) {
                                    if (this.meterStatus != 2) {
                                        this.playAnimationSegment(2);
                                        this.meterStatus = 2;
                                        this._globals.resetIsMeterConnected();
                                    }
                                }

                                break;
                            case 'CONNECTED_WITH_METER':
                                if (this.animationReady) {
                                    if (this.meterStatus != 3) {
                                        this.playAnimationSegment(3);
                                        this.meterStatus = 3;
                                        this._globals.setIsMeterConnected();
                                        console.log('clearing interval:', this.checkMeterStatusInterval);
                                        clearInterval(this.checkMeterStatusInterval);
                                    }
                                }

                                break;
                            default:
                                this.playAnimationSegment(0);
                        }

                        this.increaseMeterStatusErrorTries();
                    } else {
                        this.increaseMeterStatusErrorTries();
                    }
                } else {
                    this.increaseMeterStatusErrorTries();
                }
            },
            (error) => {
                console.log(error);
                this.increaseMeterStatusErrorTries();
            });
    }

    /**
     * Versuche der Meter-Registrierung. Ab bestimmter Anzahl an Versuchen wird ein Error-Text angezeigt
     */
    increaseMeterStatusErrorTries() {
        // Versuche erhöhen
        switch (this.meterStatus) {
            case 0:
                this.statusTries.step0++;
                break;
            case 1:
                this.statusTries.step1++;
                break;
            case 2:
                this.statusTries.step2++;
                break;
        }

        // Prüfen ob Maximum erreicht
        switch (this.meterStatus) {
            case 0: // Step0 5 Minuten -> 60 Calls
                if (this.statusTries.step0 >= 60) {
                    this.statusError.title = 'Keine Anmeldung möglich';
                    this.statusError.text = 'Bei der Vorbereitung der iONA Box ist ein Fehler aufgetreten. Soll ich es weiter versuchen oder möchten Sie den Support kontaktieren?';

                    this.statusTries = {
                        step0: 0,
                        step1: 0,
                        step2: 0,
                    };

                    this.setStep('S');
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
            case 1: // Step1 2 Minuten -> 24 Calls
                if (this.statusTries.step1 >= 60) {
                    this.statusError.title = 'Smart Meter nicht gefunden';
                    this.statusError.text = 'iONA konnte bislang keinen Smart Meter finden. Soll ich es weiter versuchen oder möchten Sie den Support kontaktieren?';

                    this.statusTries = {
                        step0: 0,
                        step1: 0,
                        step2: 0,
                    };

                    this.setStep('S');
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
            case 2: // Step2 10 Minuten -> 120 Calls
                if (this.statusTries.step2 >= 120) {
                    this.statusError.title = 'Keine Smart Meter-Verbindung';
                    this.statusError.text = 'iONA konnte bislang keine Verbindung zum Smart Meter aufbauen. Soll ich es weiter versuchen oder möchten Sie den Support kontaktieren?';

                    this.statusTries = {
                        step0: 0,
                        step1: 0,
                        step2: 0,
                    };

                    this.setStep('S');
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
        }
    }

    /**
     * Zurücksetzen der Meter-Registrierung und von vorn beginnen
     */
    retryMeterStatus() {
        this.statusTries = {
            step0: 0,
            step1: 0,
            step2: 0,
        };

        this.checkMeterStatus();
        if (this.device === 'plug') {
            this.setStep(this.onboardingSteps.devices.plug.connecting);
        } else {
            this.setStep(this.onboardingSteps.devices.box.connecting);
        }
    }

    checkOnline() {
        this._apiService.getInitialization().subscribe(
            (data: any) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        if (data.data.profile.has_electricity_meter > 0) {
                            console.warn('error occurred');
                            // this._router.navigate(['/']);
                        } else {
                            this.checkTries();
                        }
                    } else {
                        this.checkTries();
                    }
                } else {
                    this.checkTries();
                }
            },
            () => {
                this.checkTries();
            }
        );
    }

    checkTries() {
        this.tries++;

        if (this.tries >= 90) {
            this.setStep('P');
        }
    }

    setIsOnboarding() {
        this._globals.setIsOnboarding();
        this._apiService.setApplicationState(constants.application.states.live);
        this._router.navigate(['mein-haushalt']);
    }

    /**
     * Aktuellen Step der Registrierung setzen -> Im Frontend die Steps durchwechseln
     *
     * @param step
     */
    setStep(step: string) {

        // console.log('switching to step: ', step);

        this.currentStep = step;

        switch (this.currentStep) {
            case this.onboardingSteps.devices.box.powerConnect:
            case this.onboardingSteps.devices.box.lanConnect: {
                this.connect = {
                    type: 'LAN',
                    tries: 0
                };
                break;
            }
            case this.onboardingSteps.devices.box.macEntry: {
                if (this.input.mac !== null && this.input.mac.length > 0) {
                    this.registerDevice(this.input.mac, false);
                }

                break;
            }
            case 'W':
            case 'X': {
                this.connect = {
                    type: 'AP',
                    tries: 0
                };

                break;
            }
            case this.onboardingSteps.devices.plug.connecting:
            case this.onboardingSteps.devices.box.connecting:
                if (this.user.email !== null && this.user.email !== undefined) {
                    this._apiService.loginUser(this.user.email, this.user.password);
                }
                break;
        }

        // analytics stuff
        switch (this.currentStep) {
            case this.onboardingSteps.devices.box.lanConnect:
            case 'X': {
                this._analytics.eventTrack.next({
                    action: 'onboarding_start',
                    properties: {
                        category: 'Onboarding',
                        label: 'mode: ' + this.connect.type
                    }
                });

                break;
            }
            case 'I': {
                if (this.connect.type !== null) {
                    this._analytics.eventTrack.next({
                        action: 'onboarding_cancel',
                        properties: {
                            category: 'Onboarding',
                            label: 'mode: ' + this.connect.type
                        }
                    });
                }

                break;
            }
        }
    }

    /**
     * On Input focus
     * @param event
     */
    public onInputFocus(event: FocusEvent): void {
        if (event.type === 'focus') {
            event.target['placeholder'] = '';
        } else if (event.type === 'focusout') {
            event.target['placeholder'] = this.macPlaceholder;
        }
    }

    public selectDevice(device): void {
        this.device = device;
        let device_to_set = '';
        switch (device) {
            case 'box':
                this.deviceTitle = 'iONA-Box';
                this.currentStep = this.onboardingSteps.devices.box.powerConnect;
                device_to_set = constants.application.devices.box;
                break;
            case 'plug':
                this.deviceTitle = 'PowerChecker';
                this.currentStep = this.onboardingSteps.devices.plug.noteMac;
                device_to_set = constants.application.devices.plug;
                break;
            default:
                this.deviceTitle = 'iONA-Box';
                break;
        }

        console.log('setting user device to', device_to_set);

        this._userService.updateUserDevice(device_to_set);
    }


}
