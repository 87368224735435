import {Component, OnInit, OnDestroy, Renderer2, ViewChild} from '@angular/core';

import {Chart} from 'angular-highcharts';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../mock-data.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../user.service';
import * as moment from 'moment';
import {RemainingTimeIndicatorComponent} from '../../remaining-time-indicator/remaining-time-indicator.component';
import {flatMap, map} from 'rxjs/operators';

@Component({
    selector: 'live-widget',
    templateUrl: './widget.live.html',
    styleUrls: ['./widget.live.scss'],
    providers: [Globals]
})

export class LiveWidget implements OnInit, OnDestroy {

    refresh: any = [];
    chart: any = {};
    current_consumption = '0';
    showDiagrams: boolean = true;

    userHasHappyHour = false;

    currentDataset = [];

    happyHourFrom = null;
    happyHourTo = null;


    @ViewChild('happyHourIndicator', {static: false}) hhIndicator: RemainingTimeIndicatorComponent;

    mock_subs: Subscription[] = [];

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockService: MockDataService,
                private _userService: UserService) {
    }

    /**
     * on init hook
     */
    ngOnInit() {
        this.initializeChart();
        this.initializeComponent();
    }

    /**
     * on destroy hook
     */
    ngOnDestroy() {
        clearInterval(this.refresh);
        for (const sub of this.mock_subs) {
            sub.unsubscribe();
        }
    }

    /**
     * Initializes the Component with requests made
     */
    private initializeComponent(): void {
        // initialize chart for demo mode
        if (this._apiService.isDemoMode()) {
            this.getMockConsumption();
            this.getMockCurrentConsumption();
            this.refresh = setInterval(() => {
                this.getMockConsumption();
                this.getMockCurrentConsumption();
            }, 10000);
            return;
        }

        if (this._userService.getActiveUserProvider().toLowerCase() === 'enviam') {
            this.determineUserHasHappyHour();
        }

        this.getLiveConsumption();
        this.getCurrentConsumption();

        this.refresh = setInterval(() => {
            this.getLiveConsumption();
            this.getCurrentConsumption();
            if (this.userHasHappyHour) {
                this.hhIndicator.setStartTime(this.happyHourFrom, this.happyHourTo);
            }
        }, 10000);
    }

    /**
     * Update the diagram with the given dataset
     * @param dataset
     */
    private updateChart(dataset): void {
        this.chart.removeSerie(0);

        this.chart.addSerie(
            {
                name: null,
                data: dataset.map((element) => {
                    return [
                        new Date(element.timestamp).getTime(),
                        element.power
                    ];
                })
            }
        );
    }

    /**
     * Requests the current live data
     */
    private getLiveConsumption() {
        this._apiService.getLive(5, 0, 1).subscribe(
            (response: any) => {
                if (response === null || response === undefined) {
                    console.log('undefined from response');
                    return;
                }
                if (response.status === 'ok' && 'data' in response) {
                    const data = response.data.results;
                    if (data.length > 0) {
                        this.currentDataset = data;
                        this.updateChart(data);
                    }
                }
            },
            (error) => {
                console.log(error);
                this.showDiagrams = false;
            }
        );
    }

    /**
     * Aktuellen Verbraucht holen
     */
    private getCurrentConsumption() {
        this._apiService.getCurrentConsumption().subscribe(
            (response: any) => {
                if ('data' in response) {
                    if ('electricity' in response.data) {
                        if ('power' in response.data.electricity) {
                            this.current_consumption = response.data.electricity.power.toLocaleString('de-DE');
                        }
                    }
                }
            }
        );
    }

    /**
     * Request local mock data
     */
    private getMockConsumption(): void {
        const offset: number = 5;
        const limit: number = 0;
        const interval: number = 1;
        const level: number = 1;

        const s = this._mockService.getLiveData(offset, level, limit, interval)
            .subscribe(
                (response) => {
                    if (response === null || response === undefined) {
                        return;
                    }
                    if (response.length === 0) {
                        return;
                    }
                    this.updateChart(response);
                }
            );
        this.mock_subs.push(s);
    }

    /**
     * Get the current value based on mock data.
     */
    private getMockCurrentConsumption(): void {
        const s = this._mockService.getCurrentConsumption2().subscribe(
            (value: { power: number, timestam: number }) => {
                const current_consumption = value.power;
                const diff = current_consumption - Math.floor(current_consumption);
                if (diff < 0.5) {
                    this.current_consumption = Math.floor(current_consumption).toLocaleString('de-DE');
                } else {
                    this.current_consumption = Math.ceil(current_consumption).toLocaleString('de-DE');
                }
            }
        );
        this.mock_subs.push(s);
    }


    /**
     * Request whether the user participates in the happy hour program
     */
    private determineUserHasHappyHour(): void {
        this._apiService.userParticipatesInHappyHour().subscribe(
            (response) => {

                if (response === null || response === undefined) {
                    console.log('undefined from response');
                    return;
                }

                if ('data' in response) {
                    const now = moment();

                    const parsed_response_data = response.data as Array<{ from_date: string, value: number }>;
                    // filter by elements with date earlier than today
                    const filtered = parsed_response_data.filter((element) => now >= moment(element.from_date, 'DD/MM/YYYY'));
                    const sorted = filtered.sort((a, b) => {
                        const a_from = moment(a.from_date, 'DD/MM/YYYY');
                        const b_from = moment(b.from_date, 'DD/MM/YYYY');
                        if (a_from.unix() > b_from.unix()) {
                            return -1;
                        } else if (a_from.unix() < b_from.unix()) {
                            return 1;
                        }
                        return 0;
                    });

                    if ('value' in sorted[0]) {
                        this.userHasHappyHour = sorted[0].value === 1;
                        this.getHappyHourSchedule();
                    }
                }

            },
            (error) => {
            }
        );
    }

    /**
     * Request the users happyhour schedule
     */
    private getHappyHourSchedule(): void {
        const date = moment();
        this._apiService.getHappyHourSchedule(date.year(), date.month() + 1).subscribe(
            (response) => {
                this.getLiveConsumption();
                const today = response.data.find((element) => {
                    return element.dayofweek === date.isoWeekday();
                });

                this.happyHourFrom = moment().hour(today.starttime).minute(0).second(0).millisecond(0).toDate();
                this.happyHourTo = moment().hour(today.endtime).minute(0).second(0).millisecond(0).toDate();

                this.hhIndicator.setStartTime(this.happyHourFrom, this.happyHourTo);

                this.initializeChart();
            }
        );
    }

    /**
     * Initializes the chart
     */
    private initializeChart(): void {
        this.chart = new Chart({
            chart: {
                type: 'area',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 25, 0],
                events: {
                    redraw: function() {
                        this.reflow();
                    },
                    click: function() {
                        $('.sidebar').each(function() {
                            $(this).removeClass('visible');
                        });

                        $('html').addClass('sidebar-open');
                        $('.sb-overlay').addClass('visible');
                        $('.sidebar.myDevices.live-details').toggleClass('visible');
                    },
                }
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                tickInterval: 60 * 1000,
                dateTimeLabelFormats: {
                    millisecond: '%H:%M',
                    second: '%H:%M',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%d.%m',
                    week: '%d.%m',
                    month: '%m.%Y',
                    year: '%Y'
                },
                title: {
                    text: null
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                },
                crosshair: {
                    width: 1,
                    color: 'rgba(0,0,0,0.6)',
                    zIndex: 3
                },
                minPadding: 0,
                maxPadding: 0
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    align: 'right',
                    x: 60,
                    y: 15,
                    step: 2,
                    formatter: function() {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE') + ' W';
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontSize: '13px',
                    }
                },
                gridZIndex: 10,
                gridLineColor: 'rgba(0,0,0,0.10)'

            },
            tooltip: {
                xDateFormat: '%H:%M:%S Uhr',
                hideDelay: 0,
                animation: false,
                shadow: false,
                positioner: function(boxWidth: number, boxHeight: number, point: any) {
                    if ((point.plotX + this.chart.plotLeft + boxWidth) > this.chart.plotWidth) {
                        return {
                            x: (point.plotX + this.chart.plotLeft) - boxWidth - 10,
                            y: 120
                        };
                    } else {
                        return {
                            x: point.plotX + this.chart.plotLeft + 10,
                            y: 120
                        };
                    }
                },
                useHTML: true,
                headerFormat: '<div class="large">{point.key}</div>',
                pointFormat: '<div class="small">{point.y:,.0f} Watt</div>',
                style: {
                    color: '#143C8C',
                    zIndex: 1500,
                },
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                borderWidth: 0,
                borderRadius: 0
            },
            plotOptions: {
                series: {
                    showInLegend: false,
                    animation: false,
                    marker: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                area: {
                    lineWidth: 0,
                    marker: {
                        enabled: false
                    },
                    fillOpacity: 1.0,
                    zoneAxis: 'x',
                    zones: [
                        {value: 0},
                        {color: 'rgb(0, 170, 225)'},
                    ]
                }
            },
            series: [
                {
                    name: null,
                    data: []
                }
            ],
            credits: {
                enabled: false
            }
        });

        if (this.userHasHappyHour) {
            this.chart._options.plotOptions.area.zones = this.calculateNewZones();
        }
    }

    /**
     *
     */
    firstDetailViewAnalytics() {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Live-Details'
                }
            });
        }

        this._globals.setFirstDetailsViews();
    }

    private calculateNewZones(): any {
        return [
            {color: 'rgb(0, 170, 225)'},
            {value: this.happyHourFrom.getTime()},
            {color: 'rgb(20, 60, 140)',},
            {value: this.happyHourTo.getTime()},
            {color: 'rgb(0, 170, 225)'},
        ];
    }

}
